import React, { useState, useCallback } from 'react';
import { graphql } from 'gatsby';
import styled, { keyframes } from 'styled-components';

import HeroSlideshow from '@components/HeroSlideshow';
import Navigation from '@core/Navigation';
import ArrowSvg from '@icons/arrow.svg';

import mq from '@mq';
import { hyphenate, linkHover } from '@mixins';
import { scroll } from '@utils';

import Locations from '@components/Locations';

const HomePage = ({
  data: {
    contentfulHomepage: {
      title,
      heroSlideshow,
      locations,
      firstRowSpeed,
      secondRowSpeed,
      thirdRowSpeed,
      description: { description } = {},
      introduction: { introduction } = {},
      introductionHidden: { introductionHidden } = {},
    },
  },
}) => {
  const [introductionExpanded, setIntroductionExpanded] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);

  const toggleIntroductionExpanded = useCallback(() => {
    setIntroductionExpanded(previous => !previous);
  }, []);

  return (
    <>
      <Navigation />
      <HeroSlideshow
        images={heroSlideshow}
        title={title}
        firstRowSpeed={firstRowSpeed}
        secondRowSpeed={secondRowSpeed}
        thirdRowSpeed={thirdRowSpeed}
      />
      <Arrow
        hasScrolled={hasScrolled}
        onClick={() => {
          setHasScrolled(true);
          scroll.toElement('#description');
        }}
      >
        <ArrowSvg />
      </Arrow>

      <main>
        <Description id="description">
          <p>{description}</p>
        </Description>
        <Introduction>
          <h2>Einführung</h2>
          <div>
            <p>{introduction}</p>
            {!introductionExpanded && (
              <ReadMore onClick={toggleIntroductionExpanded}>mehr lesen</ReadMore>
            )}
            {introductionExpanded && (
              <IntroductionHiddenText>{introductionHidden}</IntroductionHiddenText>
            )}
          </div>
        </Introduction>
        <Locations locations={locations} />
      </main>
    </>
  );
};

const Description = styled.div`
  ${hyphenate};
  font: var(--font-h2);

  padding-top: 3.5rem;
  padding-bottom: 5rem;

  ${mq.medium} {
    padding-top: 5rem;
    padding-bottom: 15rem;
  }
`;

const Introduction = styled.div`
  padding-bottom: 12rem;
  scroll-margin-top: calc(var(--navigation-height) + var(--space));

  ${mq.medium} {
    padding-bottom: 15rem;
    scroll-margin-top: calc(var(--navigation-height) + var(--space-double));
  }

  display: grid;
  row-gap: 2rem;
  column-gap: 3.75rem;

  p {
    ${hyphenate};
    white-space: pre-line;
  }

  ${mq.medium} {
    grid-template-columns: 1fr 1fr;
  }
`;

const IntroductionHiddenText = styled.p`
  margin-top: 1em;
`;

const ReadMore = styled.div`
  text-transform: uppercase;
  font: var(--font-small);
  cursor: pointer;
  margin-top: 1em;
  ${linkHover};
`;

const wiggle = keyframes`
  0%, 60%, 100% {
    transform: translateY(0);
  } 
  
  10%, 30%, 50% {
    transform: translateY(-10px);
  }

  20%, 40% {
    transform: translateY(10px);
  } 
`;

const Arrow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 3.5rem;
  position: sticky;
  bottom: var(--space-double);
  transition: opacity var(--motion);
  opacity: ${props => (props.hasScrolled ? 0 : 1)};

  ${mq.medium} {
    position: sticky;
    padding-top: 5rem;
  }

  svg {
    width: 1.75rem;
    height: 1rem;
    cursor: pointer;
    animation: ${wiggle} 20s infinite alternate;
  }
`;

export const homePageQuery = graphql`
  query homePageQuery {
    contentfulHomepage {
      title
      description {
        description
      }
      introductionHidden {
        introductionHidden
      }
      introduction {
        introduction
      }
      heroSlideshow {
        id
        gatsbyImageData(
          height: 200
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP]
          quality: 80
        )
      }
      firstRowSpeed
      secondRowSpeed
      thirdRowSpeed
      locations {
        city
        street
        slug
        subtitle {
          subtitle
        }
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 900
            placeholder: DOMINANT_COLOR
            formats: [AUTO, WEBP]
            aspectRatio: 1.45
            resizingBehavior: FILL
            cropFocus: FACES
            quality: 80
          )
        }
      }
    }
  }
`;

export default HomePage;
