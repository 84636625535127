import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';
import { useInView } from 'react-intersection-observer';

import Image from '@core/Image';

import { grid, hyphenate, linkHover } from '@mixins';
import { isMobile } from '@utils';
import mq from '@mq';

const Locations = ({ locations }) => {
  const [activeSlide, setActiveSlide] = useState(0);

  return (
    <Container>
      {!isMobile && (
        <Grid>
          <Texts>
            <TextsInner>
              <h2>Orte</h2>
              {locations.map((location, index) => (
                <Text key={index} {...location} active={activeSlide === index} />
              ))}
            </TextsInner>
          </Texts>
          <Images>
            {locations.map((location, index) => (
              <LocationImage
                key={index}
                {...location}
                setActiveSlide={setActiveSlide}
                index={index}
              />
            ))}
          </Images>
        </Grid>
      )}
      {isMobile && (
        <>
          <h2>Orte</h2>
          <List>
            {locations.map(({ city, street, subtitle, slug, image }, index) => (
              <LocationContainer key={index}>
                <Title>
                  {street} {city}
                </Title>
                {subtitle && <Subtitle>{subtitle.subtitle}</Subtitle>}
                <Link to={slug}>weitere Informationen →</Link>
                <Image image={image} />
              </LocationContainer>
            ))}
          </List>
        </>
      )}
    </Container>
  );
};

const Text = ({ city, street, subtitle, slug, active }) => (
  <TextContainer active={active}>
    <Title>
      {street} {city}
    </Title>
    {subtitle && <Subtitle>{subtitle.subtitle}</Subtitle>}

    <Link
      to={slug}
      state={{
        modal: true,
      }}
    >
      weitere Informationen →
    </Link>
  </TextContainer>
);

const LocationImage = ({ image, setActiveSlide, index }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '-40% 0px -60% 0px',
  });

  useEffect(() => {
    if (inView) {
      setActiveSlide(index);
    }
  }, [index, inView, setActiveSlide]);

  return (
    <ImageContainer index={index}>
      <div ref={ref}>
        <Image image={image} />
      </div>
    </ImageContainer>
  );
};

const Container = styled.div`
  h2 {
    margin-bottom: 5rem;

    ${mq.medium} {
      margin-bottom: 3rem;
    }
  }
`;

const Grid = styled.div`
  ${grid};
  row-gap: 1rem;
`;

const List = styled.ul`
  display: grid;
  row-gap: 3.5rem;
`;

const LocationContainer = styled.li`
  ${grid};
  row-gap: 1rem;
`;

const Texts = styled.div`
  ${mq.medium} {
    grid-column: 1 / 3;
  }
`;

const TextsInner = styled.div`
  position: sticky;
  margin-bottom: 40vh;
  top: 15%;

  ${mq.max} {
    top: 25%;
  }
`;

const TextContainer = styled.div`
  position: absolute;
  transition: opacity var(--motion-double);
  opacity: ${props => (props.active ? 1 : 0)};
  z-index: ${props => (props.active ? 1 : 0)};
`;

const Title = styled.div`
  text-transform: uppercase;
  margin-bottom: 1rem;

  ${mq.medium} {
    margin-bottom: 0.75rem;
  }
`;

const Subtitle = styled.p`
  ${hyphenate};

  ${mq.medium} {
    margin-bottom: 1.5rem;
  }
`;

const Link = styled(GatsbyLink)`
  text-transform: uppercase;
  font: var(--font-small);
  display: block;
  ${linkHover};
`;

const Images = styled.div`
  ${mq.medium} {
    grid-column: 4 / -1;
  }
`;

const ImageContainer = styled.div`
  height: 150vh;
  padding-bottom: 3.75rem;
  padding-top: 3.75rem;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  > div {
    position: sticky;
    top: 15%;

    ${mq.max} {
      top: 25%;
    }
  }
`;

export default Locations;
