import React, { useMemo } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { getSrcSet } from 'gatsby-plugin-image';

import mq, { mqdown } from '@mq';
import { layoutWrapper } from '@mixins';
import { shuffle, chunkify } from '@utils';

const HeroSlideshow = ({
  images,
  title,
  firstRowSpeed = 10,
  secondRowSpeed = 13,
  thirdRowSpeed = 16,
}) => {
  const chunks = useMemo(() => chunkify(images, 3), [images]);

  return (
    <>
      <Container>
        <Title>
          <h1>
            {title.split(' ').map((word, index) => (
              <div key={index}>{word}</div>
            ))}
          </h1>
        </Title>
        <Row speed={firstRowSpeed} images={chunks[0]} />
        <Row speed={secondRowSpeed} largeGap images={chunks[1]} />
        <Row speed={thirdRowSpeed} images={chunks[2]} />
      </Container>
    </>
  );
};

const Row = ({ images, speed, largeGap }) => {
  const { length } = images;
  const containerWidth = useMemo(
    () =>
      images.reduce(
        (accumulator, next) => accumulator + Math.floor(next.gatsbyImageData.width),
        0
      ) +
      length * (largeGap ? 350 : 200) +
      5,
    [length, images, largeGap]
  );

  const shuffledImages = useMemo(() => shuffle(images), [images]);
  const duplicatedImages = useMemo(() => [...shuffledImages, ...shuffledImages], [shuffledImages]);

  return (
    <RowContainer speed={speed} containerWidth={containerWidth} length={length} largeGap={largeGap}>
      {duplicatedImages.map((image, index) => (
        <Image
          srcSet={getSrcSet(image)}
          sizes={`${image.gatsbyImageData.width}px`}
          key={index}
          loading="eager"
        />
      ))}
    </RowContainer>
  );
};

const Container = styled.div`
  overflow: hidden;

  ${mq.medium} {
    margin-top: 2.1875rem;
  }
`;

const Title = styled.div`
  ${layoutWrapper};
  font: var(--font-page-title);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;

  ${mqdown.medium} {
    margin-top: -2.875rem;
  }

  h1 {
    margin-left: 0.25rem;
    text-transform: uppercase;

    ${mq.medium} {
      margin-left: calc(-1 * var(--space-half));
    }
  }
`;

const RowContainer = styled.div`
  white-space: nowrap;
  will-change: transform;
  height: 200px;
  ${generateAnimation};

  margin-bottom: 3rem;

  ${mq.medium} {
    margin-bottom: var(--space-double);
  }

  &:first-child {
    margin-bottom: 0;
  }

  > * + * {
    margin-left: ${props => (props.largeGap ? '350px' : '200px')};
  }
`;

const Image = styled.img`
  display: inline-block;
`;

function generateAnimation({ speed, length, containerWidth }) {
  const duration = speed * length;
  const animation = keyframes`
    from {
      transform: translate3d(0, 0, 0);
    }

    to {
      transform: translate3d(-${containerWidth}px, 0, 0);
    }
  `;

  return css`
    animation: ${animation} ${duration.toString()}s linear infinite;
  `;
}

export default HeroSlideshow;
